import Header from "../components/header";
import PageSection from "../components/pageContent";
import { Column, Columns } from "../components/columns";
import hobanPresident from "../images/hoban-president.png";
import cathy from "../images/cathy.png";
// import laura from "../images/laura.png";
import tim from "../images/tim.png";
import tom from "../images/tom.png";
import wrapupBg from "../images/contact/contact_wrapup-bg.jpg";
import rightArrow from "../images/icons/right-arrow.svg";
import mobileWrapup from "../images/contact/contact_wrapup-mobile.jpg";

//@ts-ignore
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";

const Contact = () => {
  let isDesktop = window.innerWidth >= 1024;

  return (
    <div className="container contact">
      {!isDesktop && <Header logoColor="blue" />}
      <PageSection className="contact-section_intro">
        {isDesktop && <Header logoColor="blue" />}
        <Fade bottom>
          <h1 className="heading">Contact</h1>
        </Fade>
        <Columns className="intro-text">
          <Column className="left">
            <Fade bottom>
              <h2 className="intro-blurb">
                Let’s talk. Our goal is to connect you with the areas of the campaign that
                inspire you most. We would love to schedule a time for you to meet with a
                member of our advancement team, virtually or in-person.
              </h2>
            </Fade>
          </Column>
          <Column className="right">
            <Fade bottom>
              <div>
                <p className="school-address">
                  <b>Archbishop Hoban High School</b>
                  <br />
                  1 Holy Cross Blvd.
                  <br />
                  Akron, Ohio 44306
                </p>
                <a
                  href="mailto:everyknight@hoban.org"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="email"
                >
                  everyknight@hoban.org
                </a>
              </div>
            </Fade>
          </Column>
        </Columns>
      </PageSection>
      <PageSection className="contact-section_leadership">
        <div className="headshots">
          <div className="leader">
            <Fade bottom>
              <>
                <img src={hobanPresident} />
                <div className="leader-info">
                  <p className="name">Chris DiMauro ’99</p>
                  <p className="title">President</p>
                  <div className="contact-info">
                    <a
                      href="mailto:dimauroc@hoban.org"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="school"
                    >
                      dimauroc@hoban.org
                    </a>
                    <a href="tel:330.849.2150" target="_blank" rel="noreferrer noopener">
                      330.849.2150
                    </a>
                  </div>
                </div>
              </>
            </Fade>
          </div>
          <div className="leader">
            <Fade bottom>
              <>
                <img src={cathy} />
                <div className="leader-info">
                  <p className="name">Cathy (Keller ’80) Reeves</p>
                  <p className="title">Director of Major Gifts</p>
                  <div className="contact-info">
                    <a
                      href="mailto:reevesc@hoban.org"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="school"
                    >
                      reevesc@hoban.org
                    </a>
                    <a href="tel:330.773.8620" target="_blank" rel="noreferrer noopener">
                      330.773.8620
                    </a>
                  </div>
                </div>
              </>
            </Fade>
          </div>
          <div className="leader">
            <Fade bottom>
              <>
                <img src={tim} />
                <div className="leader-info">
                  <p className="name">Tim Tyrrell</p>
                  <p className="title">Director of Strategic Partnerships</p>
                  <div className="contact-info">
                    <a
                      href="mailto:tyrrellt@hoban.org"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="school"
                    >
                      tyrrellt@hoban.org
                    </a>
                    <a href="tel:330.773.8620" target="_blank" rel="noreferrer noopener">
                      330.773.8620
                    </a>
                  </div>
                </div>
              </>
            </Fade>
          </div>
          <div className="leader">
            <Fade bottom>
              <>
                <img src={tom} />
                <div className="leader-info">
                  <p className="name">Tom Paris&nbsp;’79</p>
                  <p className="title">Director of Annual Giving and&nbsp;Planned Giving</p>
                  <div className="contact-info">
                    <a
                      href="mailto:parist@hoban.org"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="school"
                    >
                      parist@hoban.org
                    </a>
                    <a href="tel:330.773.8620" target="_blank" rel="noreferrer noopener">
                      330.773.8620
                    </a>
                  </div>
                </div>
              </>
            </Fade>
          </div>
        </div>
      </PageSection>
      {/* {isDesktop && (
        <PageSection className="contact-section_wrapup">
          <div
            className="wrapup-bg"
            style={{ backgroundImage: `url(${wrapupBg})` }}
          >
            <Fade bottom>
              <p className="title">True to You</p>
              <p>Join us in strengthening the Hoban experience.</p>
              <Fade bottom>
                <Link to="/donate" className="cta-button">
                  <span>Donate Now</span>
                  <img src={rightArrow} />
                </Link>
              </Fade>
            </Fade>
          </div>
        </PageSection>
      )}
      {!isDesktop && (
        <PageSection className="contact-section_wrapup">
          <div className="wrapup-bg">
            <Fade bottom>
              <img src={mobileWrapup} />
              <div className="words-section">
                <p className="title">True to You</p>
                <p>Join us in strengthening the Hoban experience.</p>
                <Fade bottom>
                  <Link to="/donate" className="cta-button">
                    <span>Donate Now</span>
                    <img src={rightArrow} />
                  </Link>
                </Fade>
              </div>
            </Fade>
          </div>
        </PageSection>
      )} */}
      <PageSection className="contact-section_wrapup">
        <div
          className="wrapup-bg"
          style={
            isDesktop
              ? { backgroundImage: `url(${wrapupBg})` }
              : { backgroundImage: `url(${mobileWrapup})` }
          }
        >
          <Fade bottom>
            <p className="title">True to You</p>
            <p>Join us in strengthening the {!isDesktop && <br />}Hoban experience.</p>
            <Fade bottom>
              <Link to="/donate" className="cta-button centered margin-bottom">
                <span>Donate Now</span>
                <img src={rightArrow} />
              </Link>
            </Fade>
          </Fade>
        </div>
      </PageSection>
    </div>
  );
};

export default Contact;
